import { Component, OnInit } from '@angular/core';
import { LocalDataService } from '../../services/local-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  getData: any;
  tab: any;

  constructor( private LocalDataService: LocalDataService) { }

  ngOnInit() {
    this.getData = this.LocalDataService.myInfo;
  }

}
