import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'shankarlal-portfolio';
}
document.addEventListener('DOMContentLoaded', function() {
	window.addEventListener('scroll', function() {
		var headerAnimation = document.getElementById('headerAnimation'); //console.log(headerAnimation)
		if (document.documentElement.scrollTop > 0 || document.body.scrollTop > 0) {
			headerAnimation.classList.add('header-height')
		}
		else {
			headerAnimation.classList.remove('header-height')
		}
	});
});