import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  constructor() { }

  myInfo = [
    { navList: ['Home', 'About', 'Portfolio', 'Contact'] }
  ]
}
